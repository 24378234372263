import ApiUtil from './api';

class Api extends ApiUtil {
  getPurchased() {
    return this.get('/store/permissions');
  }

  getStoreItems() {
    return this.get('/store/products');
  }

  getSingleItemInfo(itemId) {
    return this.get(`/store/product/${itemId}`);
  }

  getSingleItemPrice(itemId) {
    return this.get(`/store/price/${itemId}`);
  }

  getPayments() {
    return this.get('/store/purchases');
  }

  getSubscriptions() {
    return this.get('/store/subscriptions');
  }

  unsubscribe(params) {
    // transactionId present only when cancelling paypal subscriptions
    if (params.transactionId) {
      return this.post('/store/paypal/subscription/cancel', {
        data: JSON.stringify(params),
      });
    } else {
      return this.post('/store/desktop/unsubscribe', {
        data: JSON.stringify(params),
      });
    }
  }

  // params: productIds (req), entityId (opt; in case of creating page subscription)
  openStripeTransaction(params) {
    return this.post('/store/desktop/transaction/open', {
      data: JSON.stringify(params),
    });
  }

  // params: transactionId (req), failureReason (opt)
  transactionFail(params) {
    return this.post('/store/desktop/transaction/failed', {
      data: JSON.stringify(params),
    });
  }

  openPaypalTransaction(params) {
    return this.post('/store/paypal/transaction/open', {
      data: JSON.stringify(params),
    });
  }

  purchasePaypalSubscription(params) {
    return this.post('/store/paypal/subscription/purchase', {
      data: JSON.stringify(params),
    });
  }

  createPaypalOrder(params) {
    return this.post('/store/paypal/order/create', {
      data: JSON.stringify(params),
    });
  }

  updatePaypalFailTrans(params) {
    return this.post('/store/paypal/transaction/failed', {
      data: JSON.stringify(params),
    });
  }

  reactivateSubscription(subscriptionId) {
    return this.post(`/store/desktop/subscription/${subscriptionId}/reactivate`);
  }

  changeSubscriptionPlan(subscriptionId, productId, provider) {
    // provider: 'desktop' (for stripe) or 'paypal'
    return this.post(`/store/${provider}/subscription/${subscriptionId}/change-product`, {
      data: JSON.stringify({ productId: productId }),
    });
  }

  checkPromoCode(params) {
    return this.put(`/store/desktop/promo/code`, {
      data: JSON.stringify(params),
    });
  }
}

export default new Api();
