export const mock = {
  "feed": [
    {
      "postItemId": "668564da61d3877802a68540",
      "userId": "606d9256b7b18c1956170dcf",
      "text": "🔦 MeWe Spotlight 🔦\n\nThe Colorado Trail is a group that shares personal experiences on the 567 mile long trail that goes between Denver and Durango. If you enjoy the outdoors and are keen on hiking or biking the CT, this group is perfect for you.\n\nhttps://mewe.com/join/coloradotrail",
      "medias": [
          {
              "mediaId": "668564da61d3877802a6853f",
              "postItemId": "668564da61d3877802a68540",
              "photo": {
                  "id": "668564d561d3877802a6853e",
                  "size": {
                      "width": 1080,
                      "height": 1080
                  },
                  "mime": "image/png",
                  "isNsfw": false,
                  "_links": {
                      "img": {
                          "href": "/api/v2/photo/ifLKbC_g3IGKNO2slASdcosXCxA7xdQSQCcy_X95MR1mhWTVYdOHeAKmhT4/{imageSize}/img?static={static}",
                          "templated": true
                      }
                  }
              }
          }
      ],
      "mediasCount": 1,
      "photosCount": 1,
      "emojis": {
          "userEmojis": [
              "👍",
              "❤",
              "🔦"
          ],
          "emojiCounts": [
              {
                  "👍": 1
              },
              {
                  "❤": 1
              },
              {
                  "🔦": 1
              }
          ],
          "counts": {
              "👍": 1,
              "❤": 1,
              "🔦": 1
          }
      },
      "pageId": "6685646f7129284eef30d079",
      "postedByPage": true,
      "permissions": {
          "comment": false,
          "remove": true,
          "edit": true,
          "canFeature": true,
          "canEmojify": true,
          "repost": true,
          "reshare": true,
          "canAddEmoji": false
      },
      "createdAt": 1720019147,
      "updatedAt": 1720019147,
      "follows": true,
      "language": "en"
  },
      {
          "postItemId": "668562960b4ec82d41521ee3",
          "userId": "606d9256b7b18c1956170dcf",
          "text": "Android - 8.1.20 version was released on June 24, 2024!\n🔧Improvements:\n• Implemented mandatory group category selection.\nWhen creating a new group or editing an existing one, users are required to select a group category.\n🐞Bug-fixes:\n• Addressed various UI fixes across the platform.",
          "emojis": {
              "userEmojis": [
                  "❤",
                  "👍",
                  "🤩"
              ],
              "emojiCounts": [
                  {
                      "❤": 1
                  },
                  {
                      "👍": 1
                  },
                  {
                      "🤩": 1
                  }
              ],
              "counts": {
                  "❤": 1,
                  "👍": 1,
                  "🤩": 1
              }
          },
          "pageId": "668562420b4ec82d41521edd",
          "postedByPage": true,
          "permissions": {
              "comment": false,
              "remove": true,
              "edit": true,
              "canFeature": true,
              "canEmojify": true,
              "repost": true,
              "reshare": true,
              "canAddEmoji": false
          },
          "createdAt": 1720017558,
          "updatedAt": 1720017558,
          "follows": true
      },
      {
        "postItemId": "6685683061d3877802a689a7",
        "userId": "606d9256b7b18c1956170dcf",
        "text": "Dear MeWe Community,\n\nOur community investment round has been a remarkable testament to your support and belief in our mission. I want to express my sincere gratitude to everyone who participated.\n\nOur commitment to MeWe’s privacy-first, decentralized platform remains steadfast, and all of you are an essential part of this vision. We’re enthusiastic about what the future holds, and for our ongoing mission to deliver on the original promise of social media—building authentic connections within a thoughtful, engaged community. We will continue to strive to empower you with the latest in user-first technologies that offer an alternative to Big Tech Social.\n\nThank you for being part of our journey to build the world’s leading decentralized social network.\n\nWith gratitude,\nJeffrey Edell\nCEO & Chairman",
        "emojis": {
            "userEmojis": [
                "🤩"
            ],
            "emojiCounts": [
                {
                    "🤩": 1
                }
            ],
            "counts": {
                "🤩": 1
            }
        },
        "pageId": "668567fd61d3877802a68997",
        "postedByPage": true,
        "permissions": {
            "comment": false,
            "remove": true,
            "edit": true,
            "canFeature": true,
            "canEmojify": true,
            "repost": true,
            "reshare": true,
            "canAddEmoji": false
        },
        "createdAt": 1720019146,
        "updatedAt": 1720019146,
        "follows": true,
        "language": "en"
    },
    {
      "postItemId": "668568ca7129284eef30d63c",
      "userId": "606d9256b7b18c1956170dcf",
      "text": "Web - 4.103.1 version was released on May 30, 2024!\n\n🐞Bug-fixes:\n• Fixed the bug that displayed a 404 error page when clicking on the username in the header of a new chat.\n• Fixed the problem where an endless spinner was displayed if a user clicked an already-used registration link.\n• Resolved the issue where an endless spinner appeared when opening some photos from MyCloud->Tags.\n• Resolved the issue where the 'X' button and the 'You have no chat requests. Click here to go back to chats' button were not clickable.\n• Fixed UI issue where the 'Jump to the latest messages' button did not display correctly.",
      "pageId": "668562420b4ec82d41521edd",
      "postedByPage": true,
      "permissions": {
          "comment": false,
          "remove": true,
          "edit": true,
          "canFeature": true,
          "canEmojify": true,
          "repost": true,
          "reshare": true,
          "canAddEmoji": false
      },
      "createdAt": 1720019145,
      "updatedAt": 1720019145,
      "follows": true,
      "language": "en"
  },
  {
      "postItemId": "668568c261d3877802a689cf",
      "userId": "606d9256b7b18c1956170dcf",
      "text": "Web - 4.104.0 version was released on June 3, 2024!\n\n🐞Bug-fixes:\n• Fixed UI issues in the Dark Theme\n• Resolved the issue where the video did not display correctly in a single post view.\n• Fixed the issue where usernames sometimes did not display in chats after scrolling.\n• Resolved the issue where the 'X' button and the ‘Done’ button on the ‘Report’ dialog were not clickable.",
      "pageId": "668562420b4ec82d41521edd",
      "postedByPage": true,
      "permissions": {
          "comment": false,
          "remove": true,
          "edit": true,
          "canFeature": true,
          "canEmojify": true,
          "repost": true,
          "reshare": true,
          "canAddEmoji": false
      },
      "createdAt": 1720019138,
      "updatedAt": 1720019138,
      "follows": true,
      "language": "en"
  },
  {
      "postItemId": "668568b87129284eef30d637",
      "userId": "606d9256b7b18c1956170dcf",
      "text": "iOS - 2.20.23 version was released on Jun 13, 2024!\n🚀New features:\n• Added the ‘Copy Link to Post’ option, making it easier to share posts with others.\n• Added the ability to see the full list of contact points (emails and phone numbers) and select only the necessary ones during migration to web3.\n🔥Improvements:\n• Applied a new tint color across the entire app, giving it a fresh and consistent look.\n• Improved a post footer bar and a comment footer bar.\n🐞Bug-fixes:\n• Fixed the issue where the app crashed after opening the ’Schedule posts’ tab.\n• Resolved the issue where the story creation time was always ‘now’ after opening it in a chat.\n• Addressed various UI fixes across the platform.\n\nhttps://apps.apple.com/co/app/mewe-network/id918464474?l=en",
      "link": {
          "description": "MeWe is the world’s largest decentralized social network. With a privacy-first ethos, it contains no ads, no targeting, and no newsfeed manipulation. It is a community focused experience with more than 650,000 interest groups, allowing users to find like-minded individuals that share their passions…",
          "title": "MeWe Network",
          "thumbnailSize": {
              "width": 564,
              "height": 296
          },
          "_links": {
              "url": {
                  "href": "https://apps.apple.com/co/app/mewe-network/id918464474?l=en"
              },
              "thumbnail": {
                  "href": "https://mewe-thumbs-master-ci.s3.amazonaws.com/KurnwX6QXZNFZ3_AChB2iMOnpxk"
              },
              "urlHost": {
                  "href": "apps.apple.com"
              }
          }
      },
      "pageId": "668562420b4ec82d41521edd",
      "postedByPage": true,
      "permissions": {
          "comment": false,
          "remove": true,
          "edit": true,
          "canFeature": true,
          "canEmojify": true,
          "repost": true,
          "reshare": true,
          "canAddEmoji": false
      },
      "createdAt": 1720019128,
      "updatedAt": 1720019128,
      "follows": true,
      "language": "en"
  },
  {
      "postItemId": "668568ad61d3877802a689ca",
      "userId": "606d9256b7b18c1956170dcf",
      "text": "Web - 4.105.0 version was released on Jun 14, 2024!\n\n🚀New features:\n• Added the ‘Copy Link to Post’ option, making it easier to share posts with others.\n🐞Bug-fixes:\n• Fixed the issue where banned from a page followers were not displayed on the ‘Blocked’ tab.\n• Resolved the issue when in some cases it was not possible to close the ‘Premium’ dialog.\n• Addressed various UI fixes across the platform.\n\n🗒If you are experiencing issues, please try logging out and logging back in, or refreshing your browser.",
      "pageId": "668562420b4ec82d41521edd",
      "postedByPage": true,
      "permissions": {
          "comment": false,
          "remove": true,
          "edit": true,
          "canFeature": true,
          "canEmojify": true,
          "repost": true,
          "reshare": true,
          "canAddEmoji": false
      },
      "createdAt": 1720019117,
      "updatedAt": 1720019117,
      "follows": true,
      "language": "en"
  },
  {
      "postItemId": "668568a461d3877802a689c5",
      "userId": "606d9256b7b18c1956170dcf",
      "text": "Android - 8.1.19.56 version was released on June 18, 2024!\n🔥Improvements:\n• Changed the size of the Emoji bar",
      "pageId": "668562420b4ec82d41521edd",
      "postedByPage": true,
      "permissions": {
          "comment": false,
          "remove": true,
          "edit": true,
          "canFeature": true,
          "canEmojify": true,
          "repost": true,
          "reshare": true,
          "canAddEmoji": false
      },
      "createdAt": 1720019108,
      "updatedAt": 1720019108,
      "follows": true,
      "language": "en"
  },
  {
      "postItemId": "6685689d7129284eef30d631",
      "userId": "606d9256b7b18c1956170dcf",
      "text": "Web - 4.107.0 version was released on June 19, 2024!\n🐞Bug-fixes:\n• Resolved the issue when a single post view was displaying again after the page refresh",
      "pageId": "668562420b4ec82d41521edd",
      "postedByPage": true,
      "permissions": {
          "comment": false,
          "remove": true,
          "edit": true,
          "canFeature": true,
          "canEmojify": true,
          "repost": true,
          "reshare": true,
          "canAddEmoji": false
      },
      "createdAt": 1720019101,
      "updatedAt": 1720019101,
      "follows": true,
      "language": "en"
  },
  {
      "postItemId": "668568957129284eef30d62c",
      "userId": "606d9256b7b18c1956170dcf",
      "text": "iOS - 2.20.24 version was released on June 24, 2024!\n🔧Improvements:\n• Implemented mandatory group category selection.\nWhen creating a new group or editing an existing one, users are required to select a group category.\n🐞Bug-fixes:\n• Addressed various UI fixes across the platform.\n\nhttps://apps.apple.com/co/app/mewe-network/id918464474?l=en",
      "link": {
          "description": "MeWe is the world’s largest decentralized social network. With a privacy-first ethos, it contains no ads, no targeting, and no newsfeed manipulation. It is a community focused experience with more than 650,000 interest groups, allowing users to find like-minded individuals that share their passions…",
          "title": "MeWe Network",
          "thumbnailSize": {
              "width": 564,
              "height": 296
          },
          "_links": {
              "url": {
                  "href": "https://apps.apple.com/co/app/mewe-network/id918464474?l=en"
              },
              "thumbnail": {
                  "href": "https://mewe-thumbs-master-ci.s3.amazonaws.com/KurnwX6QXZNFZ3_AChB2iMOnpxk"
              },
              "urlHost": {
                  "href": "apps.apple.com"
              }
          }
      },
      "pageId": "668562420b4ec82d41521edd",
      "postedByPage": true,
      "permissions": {
          "comment": false,
          "remove": true,
          "edit": true,
          "canFeature": true,
          "canEmojify": true,
          "repost": true,
          "reshare": true,
          "canAddEmoji": false
      },
      "createdAt": 1720019093,
      "updatedAt": 1720019093,
      "follows": true,
      "language": "en"
  },
  {
      "postItemId": "668562960b4ec82d41521ee3",
      "userId": "606d9256b7b18c1956170dcf",
      "text": "Android - 8.1.20 version was released on June 24, 2024!\n🔧Improvements:\n• Implemented mandatory group category selection.\nWhen creating a new group or editing an existing one, users are required to select a group category.\n🐞Bug-fixes:\n• Addressed various UI fixes across the platform.",
      "emojis": {
          "userEmojis": [
              "❤",
              "👍",
              "🤩"
          ],
          "emojiCounts": [
              {
                  "❤": 1
              },
              {
                  "👍": 1
              },
              {
                  "🤩": 1
              }
          ],
          "counts": {
              "❤": 1,
              "👍": 1,
              "🤩": 1
          }
      },
      "pageId": "668562420b4ec82d41521edd",
      "postedByPage": true,
      "permissions": {
          "comment": false,
          "remove": true,
          "edit": true,
          "canFeature": true,
          "canEmojify": true,
          "repost": true,
          "reshare": true,
          "canAddEmoji": false
      },
      "createdAt": 1720017558,
      "updatedAt": 1720017558,
      "follows": true
    }
  ],
  "order": "post",
  "users": [
    {
      "id": "606d9256b7b18c1956170dcf",
      "name": "Maciej Wasilewski :)",
      "_links": {
          "avatar": {
              "href": "/api/v2/photo/profile/{imageSize}/606d9256b7b18c1956170dcf?group=&f=F1655087237149AP5WW4",
              "templated": true
          }
      },
      "fingerprint": "F1655087237149AP5WW4",
      "firstName": "Maciej",
      "lastName": "Wasilewski :)",
      "contactInviteId": "maciek2",
      "publicLinkId": "maciek",
      "public": false
  },
    {
      "id": "606d9256b7b18c1956170dcf",
      "name": "Maciej Wasilewski :)",
      "_links": {
          "avatar": {
              "href": "/api/v2/photo/profile/{imageSize}/606d9256b7b18c1956170dcf?group=&f=F1655087237149AP5WW4",
              "templated": true
          }
      },
      "fingerprint": "F1655087237149AP5WW4",
      "firstName": "Maciej",
      "lastName": "Wasilewski :)",
      "contactInviteId": "maciek2",
      "publicLinkId": "maciek",
      "public": false
  },
      {
          "id": "606d9256b7b18c1956170dcf",
          "name": "Maciej Wasilewski :)",
          "_links": {
              "avatar": {
                  "href": "/api/v2/photo/profile/{imageSize}/606d9256b7b18c1956170dcf?group=&f=F1655087237149AP5WW4",
                  "templated": true
              }
          },
          "fingerprint": "F1655087237149AP5WW4",
          "firstName": "Maciej",
          "lastName": "Wasilewski :)",
          "contactInviteId": "maciek2",
          "publicLinkId": "maciek",
          "public": false
      }
  ],
  "pages": [
    {
      "id": "668567fd61d3877802a68997",
      "nsfw": false,
      "name": "Jeffrey Edell",
      "urlId": "jeffreyedell",
      "publicLinkId": "jeffreyedell",
      "_links": {
          "self": {
              "href": "/api/v2/pages/page/668567fd61d3877802a68997"
          },
          "profilePhoto": {
              "href": "/api/v2/photo/x-cSKYbLQUxvYdsBLgW3NymIHueCDYUKjIlWlYjhkdFmhWgGYdOHeAKmiZ0/{imageSize}/img",
              "templated": true
          },
          "coverPhoto": {
              "href": "/api/v2/photo/-4MDrOehZgFh5WKD7Y9h2Wl2Pgp312Lp-7-Gg87dGvJmhWgNcSkoTu8w1g0/{imageSize}/img",
              "templated": true
          }
      }
  },
    {
      "id": "6685646f7129284eef30d079",
      "nsfw": false,
      "name": "MeWe News and Updates",
      "urlId": "mewenewsandupdates",
      "publicLinkId": "mewenews",
      "_links": {
          "self": {
              "href": "/api/v2/pages/page/6685646f7129284eef30d079"
          },
          "profilePhoto": {
              "href": "/api/v2/photo/6G_y5XO2Rr_esegU2_i6k-4jtn5yT-8HUpnW2ame-aNmhWSJcSkoTu8w0MQ/{imageSize}/img",
              "templated": true
          },
          "coverPhoto": {
              "href": "/api/v2/photo/xLoLVPLNCWBEt69KCFzjWOKT8WzgF77zzoWmGWKL2cJmhWSQcSkoTu8w0QI/{imageSize}/img",
              "templated": true
          }
      }
  },
      {
          "id": "668562420b4ec82d41521edd",
          "nsfw": false,
          "name": "MeWe's Release Notes",
          "urlId": "mewesreleasenotes",
          "publicLinkId": "mewereleasenotes",
          "_links": {
              "self": {
                  "href": "/api/v2/pages/page/668562420b4ec82d41521edd"
              },
              "profilePhoto": {
                  "href": "/api/v2/photo/jPu3fmUJwNqRFXxoRlIpsI1TlnK7GlZRKuV9h93wNbZmhWJJcSkoTu8wzQo/{imageSize}/img",
                  "templated": true
              },
              "coverPhoto": {
                  "href": "/api/v2/photo/O82x6V2YFwrJgvRRKxLYqu9gTl-MPnA2NpdkZY6AvstmhWJPC07ILUFSHt8/{imageSize}/img",
                  "templated": true
              }
          }
      }
  ],
  "_links": {
      "nextPage": {
          "href": "/api/v2/pages/page/668562420b4ec82d41521edd/postsfeed?limit=10&b=668562960b4ec82d41521ee3&dateFrom=2024-07-03T14:39:18.705Z",
          "nextId": "bGltaXQ9MTAmYj02Njg1NjI5NjBiNGVjODJkNDE1MjFlZTMmZGF0ZUZyb209MjAyNC0wNy0wM1QxNDozOToxOC43MDVa"
      }
  }
}
