import { gifUrlRegExp, gifUrlToStill, gifUrlToGif } from 'mewe/utils/gif-utils';
import imageSizeHelper from 'mewe/helpers/image-size';
import { maxGifHeight } from 'mewe/constants';

let canPlayVideos = document.createElement('video');
canPlayVideos = canPlayVideos && canPlayVideos.canPlayType && canPlayVideos.canPlayType('video/mp4');
canPlayVideos = canPlayVideos === 'probably' || canPlayVideos === 'maybe';

export default {
  toDisplay(text, options = {}) {
    return (text || '').replace(gifUrlRegExp, (url) => {
      url = url.replace(/[?]response_id=[^& #]+/, '');

      if (url.indexOf('<a href="') === 0) return url;
      else {
        let isGiphy = url.indexOf('giphy') !== -1,
          size;

        let maxHeight = maxGifHeight;

        if (options.maxHeight < maxHeight) maxHeight = options.maxHeight;

        if (isGiphy) {
          const urlAndSize = this.getFixedHeightUrlAndSizesFromGiphyUrl(url);
          url = urlAndSize.url;
          size = urlAndSize.size;
        } else {
          size = this.getSizesFromGfycatUrl(url, options.maxWidth, maxHeight);
        }

        if (size) {
          size = imageSizeHelper
            .compute([size], {
              maxWidth: options.maxWidth || size.width,
              maxHeight: maxHeight || size.height,
            })
            .toString();
        }

        if (!size) size = `height: ${maxHeight}px`;

        if (canPlayVideos) {
          // this static image will be replaced by video element when it will be near viewport
          return `<img class='gif video-gif' src="${gifUrlToStill(url)}" style="${size}">`;
        } else {
          return `<img src="${gifUrlToGif(url)}" class="gif" style="${size}">`;
        }
      }
    });
  },

  toEdit(text = '') {
    return text.replace(gifUrlRegExp, '');
  },

  toServer(text, gifUrls) {
    if (!gifUrls) return text;

    gifUrls.forEach((gif) => {
      if (text) text += ' ' + gif;
      else text += gif;
    });

    return text;
  },

  /* turns fixed width giphy urls into fixed height urls, keeping the ratio the same
   * e.g. for param "https://.../200w.mp4#h=100" returns { url: "https://.../200.mp4#w=400", size: { width: 400, height: 200 }
   */
  getFixedHeightUrlAndSizesFromGiphyUrl(url) {
    let lastSlashIndex = url.lastIndexOf('/'),
      lastDotIndex = url.lastIndexOf('.'),
      name = url.slice(lastSlashIndex + 1, lastDotIndex),
      rest = url.slice(lastDotIndex);

    let width, height;

    if (name.endsWith('w')) {
      let parsed = parseInt(name.slice(0, name.length - 1), 10);
      if (Number.isFinite(parsed)) width = parsed;
    } else {
      let parsed = parseInt(name, 10);
      if (Number.isFinite(parsed)) height = parsed;
    }

    if (!width && !height) {
      return { url: url.slice(0, lastSlashIndex + 1) + '200' + rest };
    }

    let widthOrHeightCharToFind = width ? 'h' : 'w';

    rest =
      rest.replace(new RegExp(`#[${widthOrHeightCharToFind}]=([0-9]{1,5000})`), (txt, widthOrHeight) => {
        widthOrHeight = parseInt(widthOrHeight, 10);

        if (!Number.isFinite(widthOrHeight)) return '';

        let ratio;

        if (width) {
          ratio = 200 / widthOrHeight;
        } else {
          ratio = widthOrHeight / 200;
        }

        height = 200;
        width = Math.round(ratio * height, 10);

        return '#w=' + width;
      }) || '';

    let heightTxt;

    if (Number.isFinite(height)) {
      heightTxt = height;
    } else {
      height = 200;
      heightTxt = '200';
    }

    return {
      url: url.slice(0, lastSlashIndex + 1) + heightTxt + rest,
      size: { width: width, height: height },
    };
  },

  getSizesFromGfycatUrl(url, maxWidth, maxHeight = maxGifHeight) {
    if (!url) return { height: maxHeight, width: maxWidth };

    let m = url.match(new RegExp('h=([0-9]{1,5000})'));

    if (!m || !m.length) return { height: maxHeight, width: maxWidth };

    let height = parseInt(m[1], 10);
    if (!Number.isFinite(height)) return { height: maxHeight, width: maxWidth };

    m = url.match(new RegExp('w=([0-9]{1,5000})'));

    if (!m || !m.length) return { height: height, width: maxWidth };

    let width = parseInt(m[1], 10);
    if (!Number.isFinite(width)) return { height: height, width: maxWidth };

    return { width: width, height: height };
  },
};
