import { A } from '@ember/array';
import { each, curry, flow } from 'lodash';
import api from 'mewe/api/pages-api';
import SmartSearchApi from 'mewe/api/smart-search-api';
import PageModel from 'mewe/stores/models/page-model';
import Feed, { storePosts, pushPosts } from 'mewe/stores/models/feed-model';
import {
  getOrCreateCollectionFromHash as collection,
  setNextPage,
  getOrCreateCollectionItem,
  storeCollectionItems,
  pushCollectionItems,
} from 'mewe/stores/models/collection';
import { FeedTypes } from 'mewe/constants';
import { deserializeAndStoreAuthors } from 'mewe/fetchers/fetch-feed';
import { ds } from 'mewe/stores/ds';
import {
  promiseConnectionCheck as guard,
  intoHash,
  toPage,
  toSimpleUser,
  toFollower,
  toBanned,
  fetchUtil,
  unsetFlag,
  isMore,
} from 'mewe/fetchers/utils';
import { toPost } from 'mewe/stores/models/post-model';
import { get, emberSet, tap, getOrCreate, map } from 'mewe/utils/fp';

const modifyCommunityData = (data) => each(data.pages, (i) => (i.page.newPosts = i.communityData.newPosts));
const setPageSubscription = (data) => {
  if (data.subscription) data.page.subscription = data.subscription;
};

const setAdminFlag = (data) => each(data.pages, (i) => (i.isAdmin = true));
const setShowMore = curry((collection, data) => collection.set('canShowMore', get(data, 'hasMoreResults')));

export const getPage = getOrCreateCollectionItem(ds.pages, PageModel);
export const getFollowedPage = getOrCreateCollectionItem(ds.followed, PageModel);

export const getScheduledFeed = (id) =>
  getOrCreate(
    ds.feeds,
    Feed.extend({ id: `scheduled-${id}`, type: FeedTypes.SCHEDULED_PAGE_POSTS }),
    `scheduled-${id}`
  );

export const storePages = (pages) => {
  each(pages, (p) => {
    const existing = ds.pages.items.findBy('id', p.id);
    if (existing) existing.setProperties(p);
    // update existing model, skip serialization
    else pushCollectionItems(ds.pages, pages.map(toPage));
  });
};

export let processPageResponse = (promise) =>
  promise
    .then(tap(setPageSubscription))
    .then(get('page'))
    .then((data) => {
      emberSet(getPage(data.id), data);
      return emberSet(getPage(data.id), { isFetching: false, isFetchedFullData: true });
    });

export const fetchPage = (id) => {
  emberSet(getPage(id), { isFetching: true });
  return processPageResponse(guard(api.page({ id })));
};

export const fetchPageByUrlId = (urlId) => processPageResponse(guard(api.pageByUrlId({ urlId })));

const fetchPages = (promise, collection, isMore) =>
  fetchUtil(promise, collection)
    .then(tap(setNextPage(collection)))
    .then(get('pages'))
    .then(map(toPage))
    .then((isMore ? pushCollectionItems : storeCollectionItems)(collection))
    .then(unsetFlag(collection));

export const searchPages = (params, isMore) =>
  fetchUtil(SmartSearchApi.pages(params), ds.searchedPages)
    .then(tap(setShowMore(ds.searchedPages)))
    .then(get('results'))
    .then(map(toPage))
    .then((isMore ? pushCollectionItems : storeCollectionItems)(ds.searchedPages))
    .then(unsetFlag(ds.searchedPages));

export const fetchMoreSearchResults = (params) => searchPages(params, ds.searchedPages.canShowMore);
export const clearSearchResults = () => ds.searchedPages.set('items', A([]));

export const fetchFeaturedAll = (nextPage) => fetchPages(api.featuredAll({ nextPage }), ds.featuredAll, nextPage);
export const fetchMorePagesFeatured = () => {
  const nextPage = ds.featuredAll.nextPage;

  if (nextPage) return fetchFeaturedAll(nextPage);
};

export const fetchTrendingForBucket = (id) =>
  fetchPages(api.trendingForBucket({ id }), collection(ds.trendingForBucket, id));

export const fetchPagesForCategory = (id, nextPage) =>
  fetchPages(api.pagesForCategory({ id, nextPage }), ds.pagesForCategory.for(id), nextPage);
export const fetchMorePagesForCategory = (id) => {
  const category = ds.pagesForCategory.for(id);

  if (category.nextPage && !category.isFetching && !category.isFetchingMore)
    return fetchPagesForCategory(id, category.nextPage);
};

export const fetchCategoriesAll = () =>
  fetchUtil(api.fetchCategoriesAll(), ds.categoriesAll)
    .then(get('categories'))
    .then((data) => {
      return data.map((c) => {
        c.name = __(c.name);
        return c;
      });
    })
    .then(storeCollectionItems(ds.categoriesAll))
    .then(unsetFlag(ds.categoriesAll));

export const fetchBuckets = () =>
  fetchUtil(api.buckets(), ds.buckets)
    .then(get('buckets'))
    .then(storeCollectionItems(ds.buckets))
    .then(unsetFlag(ds.buckets));

export const fetchCategoriesForBucket = (id) =>
  fetchUtil(api.categoriesForBucket({ id, nonEmpty: true }), collection(ds.categoriesForBucket, id))
    .then(get('categories'))
    .then(storeCollectionItems(collection(ds.categoriesForBucket, id)))
    .then(unsetFlag(collection(ds.categoriesForBucket, id)));

export const fetchScheduledFeed = (id, params) =>
  fetchUtil(api.fetchScheduledFeed(id, params), getScheduledFeed(id), params)
    .then(tap(flow(get('users'), map(toSimpleUser), intoHash(ds.authors))))
    .then(tap(setNextPage(ds.feeds[`scheduled-${id}`])))
    .then((data) => {
      deserializeAndStoreAuthors(data.users);
      if (data.pages) storePages(data.pages);

      const fn = isMore(params) ? pushPosts : storePosts;
      fn(emberSet(getScheduledFeed(id), { order: 'scheduled' }), A(data.feed.map(toPost)));
      return data;
    })
    .then(unsetFlag(getScheduledFeed(id)));

export const fetchFollowed = (params) =>
  fetchUtil(api.followed(params), ds.followed)
    .then(tap(setNextPage(ds.followed)))
    .then(tap(modifyCommunityData))
    .then((data) => {
      const storeOrPush = params && params.nextPage ? pushCollectionItems : storeCollectionItems;
      storeOrPush(ds.followed, data.pages.map(toPage));
      return data;
    })
    .then(unsetFlag(ds.followed));

export const fetchMoreFollowed = () => {
  if (ds.followed.nextPage) return fetchFollowed({ nextPage: ds.followed.nextPage });
};

export const fetchInvitations = (params) =>
  fetchUtil(api.invitations(params), ds.invitations)
    .then(tap(setNextPage(ds.invitations)))
    .then((data) => {
      const storeOrPush = params && params.nextPage ? pushCollectionItems : storeCollectionItems;
      storeOrPush(ds.invitations, data.pages.map(toPage));
      return data;
    })
    .then(unsetFlag(ds.invitations));

export const fetchMoreInvitations = () => {
  if (ds.invitations.nextPage) return fetchInvitations({ nextPage: ds.invitations.nextPage });
};

export const fetchFollowers = (pageId, params) =>
  fetchUtil(api.followers(pageId, params), ds.followers.for(pageId))
    .then(tap(setNextPage(ds.followers.for(pageId))))
    .then((data) => {
      const storeOrPush = params && params.nextPage ? pushCollectionItems : storeCollectionItems;
      const items = data.followers.map((item) => {
        item.user.followed = item.joinedAt / 1000;
        item.user.roleName = item.roleName;
        return item.user;
      });
      storeOrPush(ds.followers.for(pageId), items.map(toFollower));
      return data;
    })
    .then(unsetFlag(ds.followers.for(pageId)));

export const fetchBanned = (params) =>
  fetchUtil(api.banned(params), ds.banned.for(params.pageId))
    .then(tap(setNextPage(ds.banned.for(params.pageId))))
    .then((data) => {
      const storeOrPush = params && params.ref ? pushCollectionItems : storeCollectionItems;
      storeOrPush(ds.banned.for(params.pageId), data.list.map(toBanned));
      return data;
    })
    .then(unsetFlag(ds.banned.for(params.pageId)));

export const fetchAdministrated = (params) =>
  fetchUtil(api.administrated(params), ds.administrated)
    .then(tap(setNextPage(ds.administrated)))
    .then(tap(setAdminFlag))
    .then((data) => {
      return storeCollectionItems(ds.administrated, data.pages.map(toPage));
    })
    .then(unsetFlag(ds.administrated));

export const fetchPagesById = (params) =>
  fetchUtil(api.pagesByIds(params), ds.pages)
    .then((data) => {
      return pushCollectionItems(ds.pages, data.pages.map(toPage));
    })
    .then(unsetFlag(ds.pages));
