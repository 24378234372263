import ApiUtil from './api';

class Api extends ApiUtil {
  // with this api, partiipation in public/group event is created for requesting user if he was not invited before
  getOrJoinEvent(eventId) {
    return this.post(`/event2/${eventId}/participation`);
  }

  // returns event data without 'owner' and 'participation' so it was replaced with `getOrJoinEvent`,
  // currently it's not used and it might not be needed anymore
  getEventData(eventId) {
    return this.get(`/event2/${eventId}`);
  }

  getEventParticipation(eventId) {
    return this.get(`/event2/${eventId}/participation`);
  }

  getCounters(scope) {
    if (scope === 'events') {
      return this.get('/events2/counts');
    } else {
      return this.get('/events2/group/' + scope + '/counts');
    }
  }

  getOwnerAndAttendeesCount(eventId) {
    return this.get('/event2/' + eventId + '/owner/withcount');
  }

  getAttendeesCount(eventId, type) {
    // get single counter for paticular tab ({ attending | interested | notAttending | invited })
    if (type) {
      return this.get(`/api/v2/event2/${eventId}/participations/${type}/count`);
    }
    // get all counters at
    else {
      return this.get(`/api/v2/event2/${eventId}/participations/count`);
    }
  }

  getUpcomingEvents(scope, params) {
    params.v = 2;
    let url = scope === 'events' ? '/events2/upcoming' : '/events2/group/' + scope + '/upcoming';
    return this.get(url + '?' + this.toUrlParams(params));
  }

  getInvitedEvents(scope, params) {
    params.v = 2;
    let url = scope === 'events' ? '/events2/invitations' : '/events2/group/' + scope + '/invitations';
    return this.get(url + '?' + this.toUrlParams(params));
  }

  getPastEvents(scope, params) {
    params.v = 2;
    let url = scope === 'events' ? '/events2/past' : '/events2/group/' + scope + '/past';
    return this.get(url + '?' + this.toUrlParams(params));
  }

  getHostingEvents(scope, params) {
    params.v = 2;
    let url = scope === 'events' ? '/events2/owned/upcoming' : '/events2/group/' + scope + '/owned/upcoming';
    return this.get(url + '?' + this.toUrlParams(params));
  }

  getBirthdaysEvents(scope, params) {
    let url = scope === 'events' ? '/events2/birthdays/upcoming' : '/events2/group/' + scope + '/birthdays/upcoming';
    return this.get(url + '?' + this.toUrlParams(params));
  }

  getCalendarEvents(scope, params) {
    let url = scope === 'events' ? '/events2/calendar' : '/events2/group/' + scope + '/calendar';
    return this.get(url + '?' + this.toUrlParams(params));
  }

  getEventOwner(eventId) {
    return this.get(`/api/v2/event2/${eventId}/owner`);
  }

  getParticipants(eventId, type, params) {
    return this.get('/event2/' + eventId + '/participants/' + type + '?' + this.toUrlParams(params));
  }

  searchParticipants(eventId, type, params) {
    return this.get('/event2/' + eventId + '/participants/' + type + '/search?' + this.toUrlParams(params));
  }

  searchMentionParticipants(eventId, params) {
    return this.get('/event2/' + eventId + '/participants/search?' + this.toUrlParams(params));
  }

  getAttendeeSuggestions(eventId, params) {
    return this.get(`/event2/${eventId}/invite-suggestions?${this.toUrlParams(params)}`);
  }

  add(params, urlParams, groupId) {
    if (groupId) {
      return this.post('/events2/group/' + groupId + '?' + this.toUrlParams(urlParams), {
        data: JSON.stringify(params),
      });
    } else {
      return this.post('/events2?' + this.toUrlParams(urlParams), {
        data: JSON.stringify(params),
      });
    }
  }

  setParticipation(eventId, type) {
    return this.post('/event2/' + eventId + '/participation/' + type);
  }

  getEventSettings(eventId) {
    return this.get('/event2/' + eventId + '/participation/settings');
  }

  setEventEmailNotifs(eventId, params) {
    return this.put('/event2/' + eventId + '/participation/settings/email', {
      data: JSON.stringify(params),
    });
  }

  setEventPushNotifs(eventId) {
    return this.put('/event2/' + eventId + '/participation/settings/push');
  }

  setEventDetails(eventId, params) {
    return this.put('/event2/' + eventId, {
      data: JSON.stringify(params),
    });
  }

  deleteEvent(eventId) {
    return this.del('/event2/' + eventId);
  }

  inviteUsers(eventId, params) {
    return this.post('/event2/' + eventId + '/invite-all', {
      data: JSON.stringify(params),
    });
  }

  markFeedAsSeen(eventId) {
    return this.del('/event2/' + eventId + '/wrapper');
  }

  transferOwnership(eventId, userId) {
    return this.post('/event2/' + eventId + '/owner/' + userId);
  }

  removeAttendee(eventId, userId) {
    return this.del('/event2/' + eventId + '/participation/' + userId);
  }

  getContactsInEvent(eventId, params) {
    return this.get('/event2/' + eventId + '/contacts?' + this.toUrlParams(params));
  }

  saveCalendarSettings(params) {
    return this.post('/events2/calendar/settings', {
      data: JSON.stringify(params),
    });
  }

  //scope is just to support fetch-events params structure
  loadNextPage(scope, params) {
    if (params.nextPage) {
      let url = params.nextPage.replace('/api/v2', '');
      if (params.maxResults && url.indexOf('maxResults') === -1) {
        url += '&maxResults=' + params.maxResults;
      }
      return this.get(url);
    }
  }
}

export default new Api();
