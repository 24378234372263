import { computed, get } from '@ember/object';
import { A } from '@ember/array';
import Model from 'mewe/utils/store-utils/model/model.js';
import { attr } from 'mewe/utils/store-utils/model/attribute';
import { getOrCreate } from 'mewe/utils/fp';
import { curry } from 'lodash';

var model = Model.extend({
  id: attr('string'),

  items: attr('array', {
    defaultValue: function () {
      return A();
    },
  }),

  isFetching: attr('boolean', {
    defaultValue: false,
  }),

  isFetchingMore: attr('boolean', {
    defaultValue: false,
  }),

  canShowMore: attr('boolean', {
    defaultValue: true,
  }),

  offset: computed('items.length', function () {
    return this.get('items.length');
  }),

  nextPage: attr('object'),

  promise: attr('object'),
});

model.reopenClass({
  resourceName: 'collection',
});

export default model;

export const getOrCreateCollectionItem = (collection, _class) => (id) => {
  let instance = collection.items.findBy('id', id);

  if (!instance) {
    instance = _class.create({ id: id, isFetching: true });
    collection.items.pushObject(instance);
  }

  return instance;
};

export const getOrCreateCollectionFromHash = (hash, id) => getOrCreate(hash, model, id);

export const storeCollectionItems = curry((collection, data) => collection.set('items', A(data)));

export const pushCollectionItems = curry((collection, data) => collection.get('items').pushObjects(data));

export const unshiftCollectionItems = curry((collection, data) => collection.get('items').unshiftObjects(data));

export const setNextPage = curry((collection, data) =>
  collection.set('nextPage', get(data, '_links.nextPage.href') ? data._links.nextPage.href : null)
);

export const removeItemIfExist = curry((collection, id) => {
  let found = collection.items.findBy('id', id);
  if (found) collection.items.removeObject(found);
});
