import { Ability } from 'ember-can';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ActorAbility extends Ability {
  @service account;

  @computed('user.id', 'account.globals.currentUser.id')
  get isCurrentUser() {
    return this.user.id === this.account.globals.currentUser.id;
  }

  @computed('isCurrentUser', 'user.{group.role,role.name}')
  get canEditPermissions() {
    if (this.isCurrentUser) return false;

    let currentUserGroupRole = this.user.group?.role;

    if (currentUserGroupRole && currentUserGroupRole === 'Owner') {
      return true;
    } else if (currentUserGroupRole && currentUserGroupRole === 'Admin') {
      return this.user.role?.name !== 'Owner' && this.user.role?.name !== 'Admin';
    } else {
      return false;
    }
  }

  @computed('isCurrentUser', 'user.{isContact,canSendMessage,isGroupMember,isEventAttendee}')
  get canChat() {
    if (this.user.isEventAttendee) {
      return !this.isCurrentUser && this.user.isContact;
    } else if (this.user.isGroupMember) {
      return !this.isCurrentUser && this.user.canSendMessage;
    } else {
      return false;
    }
  }

  @computed(
    'canTransferOwnership',
    'group.{isAdmin,isOwner}',
    'isCurrentUser',
    'user.{canRemove,confirmed,isCurrentUserOwnerOrAdmin,isEventAttendee,isGroupMember,role.name}'
  )
  get canRemove() {
    if (this.user.isEventAttendee) {
      return this.user.canRemove;
    } else if (this.user.isGroupMember) {
      let isOwnerAccess = this.group?.isOwner && this.user.role.name !== 'Owner';
      let isAdminAccess = this.group?.isAdmin && this.user.role.name !== 'Admin' && this.user.role.name !== 'Owner';
      return (
        !this.canTransferOwnership && // can't remove myself if I'm the owner
        (this.isCurrentUser || // I can remove myself if I'm not owner
          isOwnerAccess ||
          isAdminAccess ||
          this.user.confirmed === false)
      ); // I can remove anyone who was invited by me (not confirmed users are displayed to inviter and admins)
    } else {
      return false;
    }
  }

  @computed('isCurrentUser', 'isEventOwner', 'user.{role.name,isEventAttendee,isEventOwner,isGroupMember}')
  get canTransferOwnership() {
    if (this.user.isEventAttendee) {
      return this.isEventOwner;
    } else if (this.user.isGroupMember) {
      return this.user.role?.name === 'Owner' && this.isCurrentUser;
    } else {
      return false;
    }
  }

  @computed('isCurrentUser', 'user.participationType')
  get isEventOwner() {
    return this.user.participationType === 'Owner' && this.isCurrentUser;
  }
}
