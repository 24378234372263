import ApiUtil from './api';

/**
 * USAGE example:
 *
 * import ContactsApi from 'mewe/api/contacts-api';
 */
class Api extends ApiUtil {
  remove(contactId) {
    return this.del('/mycontacts/contact/' + contactId + '/remove');
  }

  removeByUserId(userId) {
    return this.del('/mycontacts/contact/remove?userId=' + userId);
  }

  getIndicators(params) {
    return this.get('/following/counters', {
      data: params,
    });
  }

  /**
   * params
   * message
   * userInvitees
   * emailInvitees
   * smsInvitees
   * ref
   */
  sendInvitations(params) {
    if (params && (params.emailInvitees || params.wholeAddressBook)) {
      return this.post('/mycontacts/invite', {
        data: JSON.stringify(params),
      });
    }
  }

  inviteByPublicId(inviteId) {
    return this.post(`/mycontacts/public-invite?inviteId=${inviteId}`);
  }

  listOfContacts(userId, params) {
    return this.get('/mycontacts/user/' + userId + '/contacts', {
      data: params,
    });
  }

  list(params) {
    return this.get('/mycontacts/all', {
      data: params,
    });
  }

  invited(params) {
    return this.get('/mycontacts/invited', {
      data: params,
    });
  }

  blocked(params) {
    return this.get('/mycontacts/blocked', {
      data: params,
    });
  }

  pending(params) {
    return this.get('/following/requests/received', {
      data: params,
    });
  }

  uploaded(params) {
    return this.get('/mycontacts/addressbook/contacts', {
      data: params,
    });
  }

  search(params) {
    return this.post('/mycontacts/search?' + this.toUrlParams(params));
  }

  searchInvited(params) {
    return this.get('/mycontacts/invited?' + this.toUrlParams(params));
  }

  searchUploaded(params) {
    return this.get('/mycontacts/addressbook/contacts?' + this.toUrlParams(params));
  }

  searchBlocked(params) {
    return this.get('/mycontacts/blocked?' + this.toUrlParams(params));
  }

  searchRequests(params) {
    return this.get('/mycontacts/pending?' + this.toUrlParams(params));
  }

  blockUser(userId, reportSpam) {
    const url = `/mycontacts/contact/block/user?userId=${userId}${reportSpam ? '&reportSpam=true' : ''}`;
    return this.post(url);
  }

  unblockUser(userId) {
    return this.post('/mycontacts/contact/unblock?userId=' + userId);
  }

  getSuggestedContacts(addressBookName) {
    return this.get('/mycontacts/suggestions?addressBookName=' + addressBookName);
  }

  importContacts(params) {
    return this.post('/mycontacts/suggestions/importContacts', {
      data: JSON.stringify(params),
    });
  }

  getMyContactsSettings() {
    return this.get('/mycontacts/settings');
  }

  setMyContactsSettings(params) {
    return this.put('/mycontacts/settings', {
      data: JSON.stringify(params),
    });
  }

  getGroupsToInvite(userId) {
    return this.get('/mycontacts/groups-invite/' + userId);
  }

  getUserDetails(userId, groupId, eventId) {
    if (groupId) {
      return this.get(`/group/${groupId}/member/${userId}/profile`);
    } else if (eventId) {
      return this.get(`/event2/${eventId}/participant/${userId}/profile`);
    } else {
      return this.get(`/mycontacts/user/${userId}`);
    }
  }

  profile(handle) {
    return this.get(`/following/profile/${handle}`);
  }

  getUserDetailsByInviteId(inviteId) {
    return this.get(`/mycontacts/user?inviteId=${inviteId}`);
  }

  getUserFeed(userId, params) {
    if (userId) {
      return this.getNextPageOr(`/home/user/${userId}/postsfeed`, params /*, 'apiRoot3'*/);
    }
  }

  uploadAddressbook(name, autoAddContacts) {
    return this.post(`/mycontacts/addressbook/import/initiate?service=${name}&autoAddContacts=${autoAddContacts}`);
  }

  autovalidateBeforeImport() {
    return this.post(`/api/v3/mycontacts/addressbook/user/autovalidate`);
  }

  importAddressbookContacts(params) {
    // if no addressBookName and no importId then all imported contacts will be returned
    return this.get('/mycontacts/addressbook/contacts', {
      non401: true,
      data: params,
    });
  }

  deleteAddressbooks() {
    return this.del('/mycontacts/addressbook/contacts');
  }

  makeFriend({ userId }) {
    return this.put(`/following/${userId}/favorite`);
  }

  unmakeFriend({ userId }) {
    return this.del(`/following/${userId}/favorite`);
  }

  friends(params) {
    return this.get('/mycontacts/closefriends', {
      data: params,
    });
  }
}

export default new Api();
