import Route from 'mewe/routes/route';
import ContactsApi from 'mewe/api/contacts-api';
import { inject as service } from '@ember/service';

/**
 * this route is for redirecting old style profile links eg. mewe.com/i/maciejwasilewski1
 * to new ones, based on handles eg. mewe.com/maciek
 */

export default Route.extend({
  router: service(),

  redirect({ contactInviteId }) {
    ContactsApi.getUserDetailsByInviteId(contactInviteId)
      .then((data) => {
        if (data.publicLinkId) this.router.transitionTo('invite', data.publicLinkId);
        else this.router.transitionTo('404');
      })
      .catch(() => {
        this.router.transitionTo('404');
      });
  },
});
