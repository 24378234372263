import { A } from '@ember/array';
import Service from '@ember/service';
import PS from 'mewe/utils/pubsub';

/**
 * similar to pickers service
 */

export default Service.extend({
  _registered: A([]),

  init() {
    this._super();

    PS.Sub('close.all.dialogs', this.closeAll.bind(this));
  },

  register({ name, close }) {
    const found = this._registered.find((el) => el.name === name);
    if (!found) this._registered.pushObject({ name, close });
    this.checkOpenedDialogs();
  },

  unregister({ name }) {
    const found = this._registered.find((el) => el.name === name);
    if (found) this._registered.removeObject(found);
    this.checkOpenedDialogs();
  },

  closeAll() {
    this._registered.forEach((el) => {
      el.close();
    });
    this.checkOpenedDialogs();
  },

  checkOpenedDialogs() {
    if (this._registered.length > 0) {
      document.body.classList.add('dialog-open');
    } else {
      document.body.classList.remove('dialog-open');
    }
  },
});
