import { helper as buildHelper } from '@ember/component/helper';
import { htmlSafe } from '@ember/template';
export function templatedString(value, options, useValue = false) {
  if (!value) return null;

  let finalString = value[0];
  if (!finalString) {
    return htmlSafe('');
  }

  if (typeof finalString.replace === 'function') {
    if (useValue) {
      finalString = value;
    }

    for (var property in options) {
      if (options[property]) {
        finalString = finalString.replace('{' + property + '}', options[property]);
      }
    }
  }

  return htmlSafe(finalString);
}

// TODO - would be useful to add usage there ...
export default buildHelper(templatedString);
