import PS from 'mewe/utils/pubsub';

export let closeAllDialogs = () => {
  PS.Pub('close.dropdowns');
  PS.Pub('close.all.dialogs');
  PS.Pub('close.smart.search');
};

const dialogLoadingClass = 'app-routable--dialog-loader';

export let loadingDialog = () => {
  const viewEl = document.querySelector('body > .c-app-route');
  if (viewEl?.classList && !viewEl.classList.contains(dialogLoadingClass)) {
    viewEl.classList.add(dialogLoadingClass);
  }
};

export let notLoadingDialog = () => {
  const viewEl = document.querySelector('body > .c-app-route');
  if (viewEl?.classList?.contains(dialogLoadingClass)) {
    viewEl.classList.remove(dialogLoadingClass);
  }
};
