import Service from '@ember/service';
import { setComponentTemplate } from '@ember/component';
import { getOwner } from '@ember/application';
import { tracked } from '@glimmer/tracking';
import { A } from '@ember/array';
export default class DynamicPopupsService extends Service {
  @tracked components = A();

  async openPopup(popupName, args = {}) {
    const owner = getOwner(this);
    const factory = owner.factoryFor(`component:${popupName}`);
    if (!factory) {
      let [template, module] = await Promise.all([
        import(`../pods/components/dropdowns/${popupName}/template.hbs`),
        import(`../pods/components/dropdowns/${popupName}`),
      ]);
      setComponentTemplate(template.default, module.default);
      owner.register(`component:${popupName}`, module.default);
    }
    this.components.pushObject({
      name: popupName,
      args,
    });
  }

  setupPopupWormhole() {
    if (!document.getElementById('mw-wormhole-popup')) {
      const popupWormhole = document.createElement('div');
      popupWormhole.id = 'mw-wormhole-popup';
      document.body.append(popupWormhole);
    }
  }

  closeAll() {
    const wormhole = document.querySelector('#mw-wormhole-popup');
    if (wormhole) {
      const nodeList = wormhole.childNodes;
      nodeList.forEach((node) => {
        wormhole.removeChild(node);
      });
    }
    this.components = A();
  }
}
