import api from "!../../../../../../../home/runner/work/Frontend/Frontend/src/mewe/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../../../../../../home/runner/work/Frontend/Frontend/src/mewe/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[5].use[1]!../../../../../../../home/runner/work/Frontend/Frontend/src/mewe/node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[5].use[2]!./styles.scss";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};