import assign from 'object-assign';

export const fromJavaLocale = (loc = 'en') => loc.replace('_', '-');

export const timeFromNow = (dateMillis, nowMillis, locale) => {
  return timeago(dateMillis, nowMillis, null, locale, true);
};

export const todayTomorrowOrDate = (dateMillis, nowMillis, locale, tz, monthFormat = 'long') => {
  locale = fromJavaLocale(locale);

  if (!isDifferentDay(dateMillis, nowMillis)) {
    return __('Today');
  } else if (!isDifferentDay(dateMillis, nowMillis + 24 * 60 * 60 * 1000)) {
    return __('Tomorrow');
  } else {
    const format = {
      month: monthFormat, // September 12th
      day: 'numeric',
    };
    if (tz) format.timeZone = tz;

    return new Date(dateMillis).toLocaleDateString(locale, format);
  }
};

export const toMillis = (msOrSec) => (msOrSec < 9999999999 ? msOrSec * 1000 : msOrSec);

export const isDifferentDay = (msOrSecA, msOrSecB) => {
  const aDate = new Date(toMillis(msOrSecA)),
    bDate = new Date(toMillis(msOrSecB));

  return (
    aDate.getFullYear() !== bDate.getFullYear() ||
    aDate.getMonth() !== bDate.getMonth() ||
    aDate.getDate() !== bDate.getDate()
  );
};

export const durationTextFromSeconds = (duration) => {
  const hours = Math.floor(duration / 60 / 60),
    mins = Math.floor((duration - hours * 60 * 60) / 60),
    sec = Math.floor(duration - hours * 60 * 60 - mins * 60);

  // TODO: instead of appending, use __("{hours}h {minutes}m" {seconds}s"),  __("{minutes}m" {seconds}s"), __("{seconds}s")
  let text = '';
  if (hours > 0) text += __('{hours}h ', { hours: hours });
  if (mins > 0) text += __('{minutes}m ', { minutes: mins });
  if (sec > 0) text += __('{seconds}s', { seconds: sec });
  return text;
};

// UTC text always returned so the exact same day as passed in parameters will be shown,
// regardless of current tz
export const getLocalizedBirthDateString = (year, month, day, tz, locale = 'en') => {
  return new Date(Date.UTC(year, month, day, 0, 0, 0)).toLocaleDateString(fromJavaLocale(locale), {
    timeZone: 'UTC',
    day: 'numeric',
    month: 'long',
  });
};

// returns only full days, i.e. difference of 23h59m returns 0
// Date.now(), new Date().getTime() give millis since UTC epoch (same as backend sends),
// so timezone conversions are not needed
// -days for past, +days for future
export const daysDiff = (timeMillis, nowMillis = Date.now()) => {
  const diff = (toMillis(timeMillis) - toMillis(nowMillis)) / (24 * 60 * 60 * 1000);

  return diff < 0 ? Math.ceil(diff) : Math.floor(diff);
};

// moment().unix()
export const epochSeconds = () => Math.floor(Date.now() / 1000);

// when to display 1 year ago, 1 month ago, 1 day ago, etc.
const yearThreshold = 1000 * 60 * 60 * 24 * 365, // moment: 11 months ->
  monthThreshold = 1000 * 60 * 60 * 24 * 28, // moment: 26 days
  // TODO: weekThreshold?
  dayThreshold = 1000 * 60 * 60 * 24, //moment: 22 hours
  hourThreshold = 1000 * 60 * 60, // moment: 45 mins
  minuteThreshold = 1000 * 60, // moment: 45 seconds
  nowThreshold = 1000 * 10, // under 10 seconds gets shown as 'now'
  secondThreshold = 1000;

export const timeago = (timeMillis, nowMillis, tz, locale, futureAllowed) => {
  timeMillis = toMillis(timeMillis);
  nowMillis = toMillis(nowMillis);

  locale = fromJavaLocale(locale);

  if (window.moment) {
    const now = tz ? window.moment(nowMillis).tz(tz) : window.moment(nowMillis),
      then = tz ? window.moment(timeMillis).tz(tz) : window.moment(timeMillis);

    if (!futureAllowed && now.diff(then) < 0) {
      return now.fromNow(); // to prevent showing "in a few seconds" when server time was ahead of browser time on msg creation
    }

    return then.fromNow();
  }

  const diff = timeMillis - nowMillis,
    // numeric auto displays 'yesterday', 'last month', etc
    format = (time, fieldName, numericAuto) => {
      return new Intl.RelativeTimeFormat(fromJavaLocale(locale), numericAuto && { numeric: 'auto' }).format(
        time,
        fieldName
      );
    };

  if (!futureAllowed && diff > 0) {
    return format(0, 'second', true); // to prevent showing "in X seconds" when server time was ahead of browser time on msg creation
  }

  const absDiff = Math.abs(diff),
    formatDiff = (threshold, name) => format(Math.round(diff / threshold), name);

  if (absDiff > yearThreshold) {
    return formatDiff(yearThreshold, 'year');
  } else if (absDiff > monthThreshold) {
    const tD = new Date(timeMillis),
      nD = new Date(nowMillis);
    // month lengths differ, so check if month actually changed
    const monthsChanged = tD.getUTCFullYear() * 12 + tD.getUTCMonth() - (nD.getUTCFullYear() * 12 + nD.getUTCMonth());

    if (monthsChanged) {
      if (Math.abs(monthsChanged) === 12) {
        return format(monthsChanged > 0 ? 1 : -1, 'year');
      } else {
        const showNextOrPrevMonth = Math.abs(monthsChanged) === 1;

        return format(monthsChanged, 'month', showNextOrPrevMonth);
      }
    } else {
      return formatDiff(dayThreshold, 'day');
    }
  } else if (absDiff > dayThreshold) {
    return formatDiff(dayThreshold, 'day');
  } else if (Math.round(absDiff / hourThreshold) === 24) {
    return format(diff > 0 ? 1 : -1, 'day');
  } else if (absDiff > hourThreshold) {
    return formatDiff(hourThreshold, 'hour');
  } else if (Math.round(absDiff / minuteThreshold) === 60) {
    return format(diff > 0 ? 1 : -1, 'hour');
  } else if (absDiff > minuteThreshold) {
    return formatDiff(minuteThreshold, 'minute');
  } else if (Math.round(absDiff / secondThreshold) === 60) {
    return format(diff > 0 ? 1 : -1, 'minute');
  } else if (absDiff > nowThreshold) {
    return formatDiff(secondThreshold, 'second');
  } else {
    return format(0, 'second', true); // shown as "now"
  }
};

export const formatDateTime = (timeMillis, tz, locale) => {
  timeMillis = toMillis(timeMillis);

  return new Date(timeMillis).toLocaleDateString(fromJavaLocale(locale), {
    timeZone: tz,
    timeZoneName: 'short',
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
};

export const calendarDateFormat = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
};

export const calendarDateFormatInUtc = assign({ timeZone: 'UTC' }, calendarDateFormat);

export const dateString = (format) => (timeMillis, locale) => {
  return new Date(toMillis(timeMillis)).toLocaleDateString(fromJavaLocale(locale), format);
};

export const dateStringLongMonth = dateString({ year: 'numeric', month: 'long' }); // July 2020
export const dateStringLongMonthWithDay = dateString({ year: 'numeric', month: 'long', day: 'numeric' }); // July 8, 2020
export const dateStringCalendar = dateString(calendarDateFormat); // 10/12/2020

// millis so can be used directly with timestamps
export const utcOffsetMillis = (tz, nowMillis = Date.now()) => {
  try {
    const d = new Date(nowMillis);
    // GB so no need to parse AM/PM
    const txt = d.toLocaleDateString('en-GB', {
      timeZone: tz,
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    }); // 15/10/2020, 13:20"

    const day = parseInt(txt.slice(0, 2), 10),
      month = parseInt(txt.slice(3, 5), 10),
      year = parseInt(txt.slice(6, 10), 10),
      hour = parseInt(txt.slice(12, 14), 10),
      minute = parseInt(txt.slice(15, 17), 10),
      second = d.getSeconds(),
      millis = d.getMilliseconds();

    return Date.UTC(year, month - 1, day, hour, minute, second, millis) - nowMillis;
  } catch (e) {
    return window.moment(nowMillis).tz(tz).utcOffset() * 60 * 1000;
  }
};

export const shiftToUtc = (tz, nowMillis = Date.now()) => {
  return nowMillis - utcOffsetMillis(tz, nowMillis);
};

export const shiftFromUtc = (tz, nowMillis = Date.now()) => {
  return nowMillis + utcOffsetMillis(tz, nowMillis);
};

export const utcTimeOfDayMillis = (date = new Date()) => {
  if (typeof date === 'number') date = new Date(toMillis(date));
  return (
    date.getUTCHours() * 60 * 60 * 1000 +
    date.getUTCMinutes() * 60 * 1000 +
    date.getUTCSeconds() * 1000 +
    date.getUTCMilliseconds()
  );
};

export const utcMidnightMillis = (date = new Date()) => {
  if (typeof date === 'number') date = new Date(toMillis(date));
  return Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0);
};

// will only return long name, can't be used as e.g. param to localeTimeString regardless of tzFormat
export const tzName = (tz, locale, tzFormat = 'long') => {
  try {
    const d = new Date();
    const txt = d.toLocaleTimeString(locale, {
      timeZone: tz,
      timeZoneName: tzFormat,
      second: '2-digit',
    });
    const onlySeconds = d.toLocaleTimeString(locale, {
      timeZone: tz,
      second: '2-digit',
    });

    return txt.replace(onlySeconds, '').replace(/[\s]/, '');
  } catch (e) {
    if (window.moment) return window.moment().tz(tz).format('z');
    else return tz;
  }
};

export const is12HourFormat = (locale) => {
  const d = new Date();
  return (
    d.toLocaleTimeString(locale, { hour: '2-digit' }) !==
    d.toLocaleTimeString(locale, { hour: '2-digit', hour12: false })
  );
};

export const browserTzId = () => {
  if (window.moment) return window.moment.tz.guess();

  return new Intl.DateTimeFormat().resolvedOptions().timeZone;
};
