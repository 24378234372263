import EmberObject from '@ember/object';
import config from 'mewe/config';
import CurrentUserStore from 'mewe/stores/current-user-store';
import * as Sentry from '@sentry/ember';
import { ds } from 'mewe/stores/ds';

var globals = EmberObject.create({
  apiHost: config.apiHost,
  currentUser: CurrentUserStore.getState(),
  ds: ds,
});

export const initialize = (application) => {
  CurrentUserStore.getState().deferred.promise.then(() => {
    const user = CurrentUserStore.getState();

    globals.set('currentUser', user);

    Sentry.configureScope((scope) => {
      scope.setUser({
        id: user.id,
      });
    });
  });

  // let g = App.__container__.lookup('globals:main');
  // if(g) return;

  application.register('globals:main', globals, {
    singleton: true,
    instantiate: false,
  });

  application.inject('component', 'globals', 'globals:main');
  application.inject('controller', 'globals', 'globals:main');
  application.inject('route', 'globals', 'globals:main');
  application.inject('service', 'globals', 'globals:main');
};

export default {
  name: 'globals',
  initialize,
};
