export const keys = {
  developerTimezone: 'developerTimezone', // can be used by tester to manually set timezone
  chats: 'chats',
  cartItems: 'cartItems',
  msgNumberString: 'msgNumberString',
  latestEmojiKey: 'latestEmojiKey',
  tokens: 'tokens',
  currentUser: 'currentUser',
  myWorldUnreadPosts: 'myWorldUnreadPosts',
  ftueUnconfirmedUser: 'ftueUnconfirmedUser',
  registration: 'registration',
  selectedSkinTone: 'selectedSkinTone',
  notifications: 'notifications',
  smsSent: 'smsSent',
  isDarkThemeEnabled: 'isDarkThemeAllowed', // "isDarkThemeAllowed" value keep for backward compatibility
  composedMsgsData: 'composedMsgsData',
  composedCommentsData: 'composedCommentsData',
  publiGroupsLocale: 'publiGroupsLocale',
  logout: 'logout', // used for having logout on all tabs
  needTosAccepted: 'needTosAccepted', // consumer only, SG-17628
  tosAcceptedTime: 'tosAcceptedTime', // BE caches tos-acceptance value so this is needed to avoid spamming user with tos popups
  purchasedItems: 'purchasedItems',
  desktopSettings: 'desktopSettings',
  chatFilter: 'chatFilter',
  entriesMarkSeen: 'entriesMarkSeen',
  paypalTransactionStatus: 'paypalTransactionStatus',
  titleCount: 'titleCount',
  didSetInterests: 'didSetInterests',
  filterFavoritesInContacts: 'filterFavoritesInContacts',
  sessionData: 'sessionData',
  unmuteVideos: 'unmuteVideos',
  videoFeedAutoPlaySetting: 'videoFeedAutoPlaySetting',
  xTraceId: 'trc',
  authMode: 'authMode',
  clickedCampaigns: 'clickedCampaigns',
};

// the keys below should remain in storage to avoid losing their information on logout`
export const keysShouldRemainInStorage = ['videoFeedAutoPlaySetting'];

// no "userId" suffix for these keys
export const keysWithoutSuffix = [
  'needTosAccepted',
  'tosAcceptedTime',
  'notifications',
  'currentUser',
  'ftueUnconfirmedUser',
  'registration',
  'selectedSkinTone',
  'logout',
  'smsSent',
  'sessionData',
  'videoFeedAutoPlaySetting',
  'trc',
  'authMode',
];
