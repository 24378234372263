import { computed } from '@ember/object';
import Model from 'mewe/utils/store-utils/model/model.js';
import { attr } from 'mewe/utils/store-utils/model/attribute';
import CurrentUserStore from 'mewe/stores/current-user-store';
import { fromJavaLocale } from 'mewe/utils/datetime-utils';

var model = Model.extend({
  productId: attr('string'),
  amount: attr('object'),
  entity: attr('object'),
  card: attr('object'),
  expiresAt: attr('number'),
  renewsAt: attr('number'),
  isCancelled: attr('boolean'),
  name: attr('string'),
  platform: attr('string'),
  provider: attr('string'),

  nameText: computed('productId', function () {
    if (~this.get('productId').indexOf('store.donation')) return __('Donation of Support');
    if (~this.get('productId').indexOf('store.premium')) return __('MeWe Premium');
    if (~this.get('productId').indexOf('store.calls')) return __('Voice and Video Calls');
    if (~this.get('productId').indexOf('store.journals')) return __('Journals');
    if (~this.get('productId').indexOf('store.page'))
      return __('Single Page Subscription - {pageName}', { pageName: this.get('entity.name') });
    if (~this.get('productId').indexOf('store.storage')) {
      const storageTierName = this.get('productId').slice(this.get('productId').lastIndexOf('.') + 1);
      const storageTierSize = storageTierName === 'tier1' ? 50 : storageTierName === 'tier2' ? 100 : 500;
      return __('Storage {size}GB', { size: storageTierSize });
    }
  }),

  paymentDetailsText: computed('provider', 'card', function () {
    if (this.provider === 'desktop' && this.get('card.last4'))
      return __('Card: **** **** **** {cardNo}', { cardNo: this.get('card.last4') });
    else if (this.provider === 'paypal') return __('Payment via PayPal');
  }),

  subscriptionText: computed('provider', 'amount.displayValue', function () {
    const isAnnual = ~this.productId.indexOf('yearly');

    // for apple/google subscriptions we don't show price as they are unreliable (SUB-132).
    // prices can change in app stores during the subscription period and we'd have incorrect
    // one from server because we don't store price from the moment of particular purchase
    if (this.provider === 'apple')
      return __(' {periodly} iOS subscription', {
        period: isAnnual ? __('year') : __('month'),
        periodly: isAnnual ? __('Annual') : __('Monthly'),
      });
    else if (this.provider === 'google')
      return __(' {periodly} Android subscription', {
        period: isAnnual ? __('year') : __('month'),
        periodly: isAnnual ? __('Annual') : __('Monthly'),
      });
    else
      return __(' {periodly} subscription: {price}/{period}', {
        price: this.get('amount.displayValue'),
        period: isAnnual ? __('year') : __('month'),
        periodly: isAnnual ? __('Annual') : __('Monthly'),
      });
  }),

  endDateText: computed('expiresAt', function () {
    const locale = fromJavaLocale(CurrentUserStore.getState().get('locale'));
    return new Date(this.expiresAt).toLocaleDateString(locale, { year: 'numeric', month: 'short', day: 'numeric' }); // "Nov 12, 2018";
  }),

  renewalText: computed('expiresAt', 'isCancelled', function () {
    return this.get('isCancelled')
      ? __('Subscription cancelled. Ends on: {date}', { date: this.get('endDateText') })
      : __('Renews automatically on: {date}', { date: this.get('endDateText') });
  }),

  canChangePlan: computed('productId', function () {
    return ~this.get('productId').indexOf('store.storage') && this.provider === 'desktop';
  }),
});

model.reopenClass({
  resourceName: 'store-subscription',
});

export default model;
