import { observer, computed } from '@ember/object';
import { on } from '@ember/object/evented';
import { A } from '@ember/array';
import PromiseProxyMixin from '@ember/object/promise-proxy-mixin';
import ObjectProxy from '@ember/object/proxy';
import PS from 'mewe/utils/pubsub';
import PurchasesStore from 'mewe/stores/purchases-store';
import Storage from 'mewe/shared/storage';
import Verbose from 'mewe/utils/verbose';
import Service, { inject as service } from '@ember/service';
import { fetchSingleItem } from 'mewe/fetchers/fetch-store';
import EmojiUtils from 'mewe/utils/emoji-utils';
import { fetchPurchasedItems, fetchStoreItems } from 'mewe/fetchers/fetch-store';

const verbose = Verbose({ prefix: '[Purchase Service]', color: 'darkorange', enabled: false }).log;

const ObjectPromiseProxy = ObjectProxy.extend(PromiseProxyMixin);

let promise = null;
let promiseResolve = null;

const fetchPurchased = () => {
  let retryCount = 0;

  promise = new Promise((resolve) => {
    fetchPurchasedItems()
      .then(_fetchSuccess.bind(this))
      .catch((err) => {
        // sometimes err is undefined (found by sentry /issues/3348573786)
        if (err && err.status >= 500 && err.status < 600) {
          _fetchRetry(err, retryCount);
        } else {
          resolve(false);
        }
      });

    promiseResolve = resolve;
  });

  return promise;
};

const _fetchSuccess = (response) => {
  let items = response.assets;

  PurchasesStore.send('handlePurchased', A(items));

  Storage.set(Storage.keys.purchasedItems, JSON.stringify(items));

  verbose('fetched purchased from server');

  promiseResolve(items);
};

const _fetchRetry = (err, retryCount) => {
  const _retryFunc = function (timeout) {
    setTimeout(() => {
      fetchPurchasedItems().then(_fetchSuccess.bind(this)).catch(_fetchRetry.bind(this, err, retryCount));
    }, timeout);
  };

  if (retryCount === 0) {
    _retryFunc(3000);
  } else if (retryCount === 1) {
    _retryFunc(5000);
  } else if (retryCount === 2) {
    _retryFunc(10000);
  } else {
    verbose('err', err);
    promise.reject(err);
  }

  retryCount++;
};

const getPurchased = () => {
  if (promise) return promise;
  else return fetchPurchased();
};

const checkStore = (name) => PurchasesStore.getState().get(name);

const promisify = (name) => {
  let promise = getPurchased().then(() => ({ purchased: checkStore(name) }));
  return ObjectPromiseProxy.create({ promise });
};

const resolved = () => ObjectPromiseProxy.create({ promise: Promise.resolve({ purchased: true }) });

export default Service.extend({
  settings: service('settings'),

  init: function () {
    this._super();

    try {
      const cached = JSON.parse(Storage.get(Storage.keys.purchasedItems));

      if (cached) {
        verbose('restore purchased items from storage');
        promise = Promise.resolve(cached);
        PurchasesStore.send('handlePurchased', A(cached));
      }
    } catch (e) {}

    this.getPurchased().then(() => {
      if (!this.isDestroyed && !this.isDestroying) {
        this.set('purchasesLoaded', true);
      }

      this.checkExpiredTheme();
    });

    this.set('storeState', PurchasesStore.getState());

    PS.Sub('tokens.refresh', () => {
      verbose('token refresh - fetch purchased items');
      fetchPurchased();
    });

    PS.Sub('navigation.reload', () => {
      verbose('page refresh - fetch purchased items');
      fetchPurchased();
    });

    PS.Sub('store.permissions.updated', () => {
      verbose('permissions updated - fetch purchased items');

      // fresh fetch of permissions, some of them might be emojis so also pass them for processing
      fetchPurchased().then((data) => {
        EmojiUtils.addPurchasedItems(data.assets);
      });

      // there was a change in store permissions so update store items to have them up to date
      fetchStoreItems();
    });
  },

  // switch to default theme if user has enabled other one but his 'premium' expired and he's not purchased theme
  checkExpiredTheme() {
    this.settings.promise().then(() => {
      const state = PurchasesStore.getState();

      if (this.settings.activeTheme === 'dark') {
        if (!state.hasPremium && !state.hasDarkTheme) {
          this.settings.saveTheme('mewe');
        }
      }
    });
  },

  getItemIfLoaded(id) {
    return PurchasesStore.getState().storeItems.findBy('id', id);
  },

  // always returns promise - it can be resolved already if item exists in store,
  // otherwise it will resolve after fetching item from server
  getItemPromise(id) {
    const promise = new Promise((resolve, reject) => {
      let item = this.getItemIfLoaded(id);
      if (item) resolve(item);
      else {
        fetchSingleItem(id)
          .then((item) => {
            item = this.getItemIfLoaded(id);
            resolve(item);
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
    return promise;
  },

  fetchPurchased() {
    return fetchPurchased();
  },

  getPurchased() {
    return getPurchased();
  },

  purchasedEmojis() {
    return new Promise((resolve, reject) => {
      getPurchased()
        .then((items) => resolve(items?.filter?.((el) => ~el.indexOf('emoji-'))))
        .catch(reject);
    });
  },

  cartUpdated: on(
    'init',
    observer('storeState.cartItems.length', 'storeState.cartItems.@each.selectedProduct', function () {
      if (this.purchasesLoaded) {
        const items = this.get('storeState.cartItems').map((item) => {
          return `${item.id}_${item.selectedProduct.productId}`;
        });

        Storage.set(Storage.keys.cartItems, JSON.stringify(items));
      }
    })
  ),

  darkTheme: computed(function () {
    return promisify('hasDarkTheme');
  }),

  premium: computed(function () {
    return promisify('hasPremium');
  }),
});
